function setEqualHeight() {
    $(".grid-product .grid-element-content-header-image").matchHeight();
    $(".grid-product .grid-element-title").matchHeight();
    $(".grid-product .prices-small").matchHeight();
    $(".news-image-wrapper,.news-content-wrapper").matchHeight();
    $(".novice-postnina .grid-element-content").matchHeight();
    $(".grid-news-item-017 > article, .facebook").matchHeight();
    $(".grid-news-item-017 > .title-wrapper").matchHeight();
}
$(window).on("load",function() {
    setEqualHeight();
});
$(window).resize(function() {
    setEqualHeight();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height",maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height","");
            $('#main-menu').css("height","");
        });
    }
});

$(window).bind('scroll', function() {
    var current_breakpoint  = getSkelSize(),
        current_skel_size   = getNumSkelSize(),
        header_top          = 0,
        scroll_limit        = 85,
        logo_initial_height = 145,
        logo_end_height     = 60,
        logo_height;
    switch (current_breakpoint) {
        case "xlarge":
            scroll_limit        = 65;
            logo_initial_height = 125;
            logo_end_height     = 60;
            break;
        case "large":
            scroll_limit        = 20;
            logo_initial_height = 80;
            logo_end_height     = 60;
            break;
        case "medium":
            scroll_limit        = 50;
            logo_initial_height = 125;
            logo_end_height     = 60;
            break;
        case "small":
            scroll_limit        = 40;
            logo_initial_height = 125;
            logo_end_height     = 60;
            break;
        case "xsmall":
            scroll_limit        = 60;
            logo_initial_height = 125;
            logo_end_height     = 60;
            break;
        default:
            scroll_limit        = 85;
            logo_initial_height = 145;
            logo_end_height     = 60;
    }
    if (current_skel_size > 3) {
        if ($(window).scrollTop() <= scroll_limit) {
            logo_height = logo_initial_height - $(window).scrollTop();
        } else {
            logo_height = logo_end_height;
        }
        $("#logo a").css("height", logo_height + "px");
    } else {
        if ($(window).scrollTop() < scroll_limit) {
            header_top = - $(window).scrollTop();
        } else {
            header_top = - scroll_limit;
        }
    }
    $("#header-wrapper").css("top", header_top + "px");
});
